<template>
  <div>

    <div class="mb-10">
      <div class="text-lg font-bold mb-5">BNPL</div>
      <div class="grid grid-cols-2 sm:grid-cols-5 mb-8 w-full">
        <div class="col-span-1">
          <button
            type="button"
            class="btn btn-sm btn-green align-middle"
            @click.prevent="openActivateBNPLModal"
            :disabled=staff.bnpl_status
          >
            Activate BNPL
          </button>
        </div>
        <div class="col-span-1">
          <button
            type="button"
            class="btn btn-sm btn-red align-middle"
            @click.prevent="openDeactivateBNPLModal"
            :disabled=!staff.bnpl_status
          >
            Deactivate BNPL
          </button>
        </div>

      </div>
    </div>


    <div class="mb-10">
      <div class="text-lg font-bold mb-5">
        Loan Summary
      </div>
      <div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
        <div class="col-span-1 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">Credit Limit</div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
            {{ profileData("credit_limit", 0) | currency }}
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
        >
          <div class="opacity-75 text-sm font-medium mb-1">
            Standard Loan Limit
          </div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
            {{ profileData("standard_loan_limit", 0) | currency }}
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200"
        style="margin-top: -1px"
      >
        <div class="col-span-1 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">
            Total Loan Amount
          </div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
            {{ totalLoanAmount | currency }}
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
        >
          <div class="text-sm font-medium mb-1 text-pink-500">
            Current Loan Amount
          </div>
          <div
            class="text-pink-300 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
            {{ currentLoanAmount | currency }}
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200"
        style="margin-top: -1px"
      >
        <div class="col-span-1 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">
              Standard Loan Interest Rate
          </div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
            {{ standardLoanInterestRate }}
          </div>
        </div>
      </div>
    </div>

    <datatable :data="loans" :columns="loanColumns" />

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="confirmSendModal"
      @close="removeError"
    >
      <h4 class="text-2xl font-bold mb-8">
        Confirm
      </h4>

      <div>
        <template v-if="error">
          <div class="alert alert-red-soft -mt-6 mb-10">
            <span class="alert-icon">!</span>
            <span class="text-xs font-normal">{{ error }}</span>
          </div>
        </template>
        <p class="font-bold text-base mb-2">Letter of Indebtedness</p>
        <p>Confirm you want to send a mail attaching letter of indebtedness to the user.</p>
        <div class="text-center mt-6">
          <button
            @click.prevent="sendLetterOfIndebtedness"
            class="button bg-blue-500 text-white hover:bg-blue-600"
            :disabled="loading"
          >
            <template v-if="loading">Sending...</template>
            <template v-else>Confirm</template>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="mailSuccessModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Mail sent successfully
      </div>
      <div class="text-sm mb-10">
        A PDF will be attached to the user's email.
      </div>

      <button
        type="button"
        class="button bg-blue-500 hover:bg-blue-600 text-white"
        @click.prevent="$refs.mailSuccessModal.close"
      >
        Okay
      </button>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="activateBNPLModal"
      @close="removeError"
    >
      <h4 class="text-xl font-bold mb-6">Confirm BNPL Activation</h4>

      <template v-if="error">
        <div class="alert alert-red-soft mb-10">
          <div class="alert-icon">!</div>
          <span>{{ error }}</span>
        </div>
      </template>

      <div class="mb-6">Are you sure you want to continue?</div>

       <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-md btn-red-soft mr-2"
          @click.prevent="closeActivateBNPLModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-blue btn-md"
          @click.prevent="ActivateBNPL"
        >
          <span v-if="loading">Activating</span>
          <span v-else>Confirm</span>
        </button>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="deactivateBNPLModal"
      @close="removeError"
    >
      <h4 class="text-xl font-bold mb-6">Confirm BNPL Deactivation</h4>

      <template v-if="error">
        <div class="alert alert-red-soft mb-10">
          <div class="alert-icon">!</div>
          <span>{{ error }}</span>
        </div>
      </template>

      <div class="mb-6">Are you sure you want to continue?</div>

      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-red-soft btn-md mr-2"
          @click.prevent="closeDeactivateBNPLModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-blue btn-md w-full"
          @click.prevent="DeactivateBNPL"
        >
          <span v-if="loading">Deactivating</span>
          <span v-else>Confirm</span>
        </button>
      </div>
    </modal>
     <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">Success!</div>

      <button
        type="button"
        class="button bg-blue-500 hover:bg-blue-600 text-white"
        @click.prevent="$refs.successModal.close()"
      >
        Okay
      </button>
    </modal>

  </div>
</template>
<script>
export default {
  props: {
    staff: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      loanId: "",
      loanColumns: [
        {
          name: "loan_amount",
          th: "Loan Amount",
          render: (loan, amount) =>
            `₦ ${this.$options.filters.currency(amount)}`,
        },
        {
          name: "loan_type",
          th: "Loan Type",
          render: (loan, type) =>
            this.$options.filters.sentenceCase(type.replace("_", " ")) +
            " Loan",
        },
        {
          name: "tenure",
          th: "Loan Tenure",
          render: (loan, tenure) => `${tenure} Months`,
        },
        {
          name: "interest_rate",
          th: "Interest Rate"
        },
        {
          name: "source",
          th: "Source"
        },
        {
          name: "status",
          th: "Status",
          render: (loan, status) => {
            switch (status) {
              case "approved":
                return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Approved
									</div>`;
              case "disbursed":
                return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Disbursed
									</div>`;    
              case "declined":
                return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-3 py-2 inline-block">
										Declined
									</div>`;
              case "paid":
                return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Paid
									</div>`;
              case "pending":
              default:
                return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
            }
          },
        },
        {
          name: "created_at",
          th: "Request Date",
          render: (loan, created_at) =>
            this.$options.filters.dateFormat(created_at, "D, dd M Y h:ia"),
        },
        {
          className: "w-1/10",
          name: "action",
          th: "Action",
          render: (loan) => {
            return `
                  <div class="flex items-center">
                    <button
                      class="btn btn-blue btn-sm mr-5"
											${loan.status === "paid" ? "disabled" : ""}
                      data-click="openConfirmSendModal(${loan.id})"
                    >
                      Letter
                    </button>
                  </div>
                `;
          },
        },
      ],
    };
  },
  computed: {
    loans() {
      return this.staff?.loan || [];
    },
    currentLoanAmount() {
      const activeLoan = this.loans.find(loan => loan.status === "disbursed");
      return activeLoan?.loan_amount || 0;
    },
    standardLoanInterestRate() {
      return this.staff?.profile?.standard_loan_interest_rate ?? 0
    },
    totalLoanAmount() {
      return (
        this.loans &&
        this.loans.reduce(
          (last, transaction) => last + Number(transaction.loan_amount),
          0
        )
      );
    },
  },
  beforeMount() {},
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    openActivateBNPLModal() {
      this.$refs.activateBNPLModal.open();
    },
    closeActivateBNPLModal() {
      this.$refs.activateBNPLModal.close();
    },
    openDeactivateBNPLModal() {
      this.$refs.deactivateBNPLModal.open();
    },
    closeDeactivateBNPLModal() {
      this.$refs.deactivateBNPLModal.close();
    },
    loanCount(key) {
      const loan_counts = this.creditHistory?.total_summary?.loan_count;
      return loan_counts && loan_counts[key] ? loan_counts[key] : 0;
    },
    profileData(key, default_value = "N/A") {
      const data = this.staff?.profile?.[key];
      return data ? data : default_value;
    },
    openConfirmSendModal(loanId) {
      this.loanId = loanId;
      this.$refs.confirmSendModal.open();
    },
    closeConfirmSendModal() {
      this.$refs.confirmSendModal.close();
    },
    removeError() {
      this.error = "";
    },
    async ActivateBNPL() {
      this.loading = true;
      await this.$post({
        url: `${this.$baseurl}/staff/update-bnpl-status/${this.staff.id}`,
        data: {
          status: true
        },
        headers: this.headers,
        success: () => {
          this.closeActivateBNPLModal();
          this.$refs.successModal.open();
        },
        error: () => {
          this.requestError = 'there was an issue please try again later';
        },
      });
      this.loading = false;
    },
    async DeactivateBNPL() {
      this.loading = true;
      await this.$post({
        url: `${this.$baseurl}/staff/update-bnpl-status/${this.staff.id}`,
        data: {
          status: false
        },
        headers: this.headers,
        success: () => {
          this.closeDeactivateBNPLModal();
          this.$refs.successModal.open();
        },
        error: () => {
          this.requestError = 'there was an issue please try again later';
        },
      });
      this.loading = false;
    },
    async sendLetterOfIndebtedness() {
      this.loading = true;
      const loanId = this.loanId
      await this.sendRequest("admin.loans.letterOfIndebtedness", loanId, {
        success: () => {
          this.error = "";
          this.closeConfirmSendModal();
          this.$refs.mailSuccessModal.open();
        },
        error: (error) => {
					if(error.response.status >= 500) {
						this.error = "There was an error please try again later";
						return
					}
					this.error = error.response.data.message
        },
      });
      this.loading = false;
    },
  },
};
</script>
